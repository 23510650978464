<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="newCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-badge pill variant="light-primary" v-if="newCount">
          {{ newCount }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <span v-if="items">
        <b-link v-for="item in items" :key="item.id" @click="redirectTo(item)">
          <b-media>
            <!-- <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            />
          </template> -->
            <p class="media-heading">
              <span :class="{ 'font-weight-bolder': !item.is_read }">
                {{ item.subject }}
              </span>
            </p>
            <small class="notification-text" v-html="item.content"></small>
          </b-media>
        </b-link>
      </span>

      <!-- System Notification Toggler -->
      <!-- <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">System Notifications</h6>
        <b-form-checkbox :checked="true" switch />
      </div> -->

      <!-- System Notifications -->
      <!-- <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar size="32" :variant="notification.type">
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link> -->
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >
        Read all notifications
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
  import { ref } from '@vue/composition-api';
  import VuePerfectScrollbar from 'vue-perfect-scrollbar';
  import Ripple from 'vue-ripple-directive';

  import notificationsApi from '@api/notifications';
  import { queryParameters } from '@/schema';

  export default {
    components: {
      VuePerfectScrollbar,
    },
    directives: {
      Ripple,
    },
    setup(props) {
      const perfectScrollbarSettings = {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      };

      const items = ref({});
      function loadNotifications() {
        const params = {
          sort: queryParameters.sort,
          page: { size: 10, after: 1 },
          status: queryParameters.status,
        };

        notificationsApi
          .list(params)
          .then(({ data }) => {
            items.value = data;
          })
          .catch((err) => {
            //
          })
          .finally(() => {
            //
          });
      }

      const hasNew = ref(false);
      const newCount = ref(0);
      function loadNotificationCount() {
        notificationsApi.getstatus().then(({ data }) => {
          if (data) {
            loadNotifications();
            if (data.new > newCount.value) {
              hasNew.value = true;
              newCount.value = data.new;

              if (process.browser) {
                window.localStorage.setItem('lastcount', data.new);
                // this.$refs.alarm.play();
              }
            }
          }
        });
      }

      function redirectTo(event) {
        const { notification_type, notification_data, header_id: id } = event;

        if (id) {
          event.is_read = true;
          event.date_read = this.inputDateTimeToUnix(new Date());
          notificationsApi
            .update(event)
            .then(() => {
              loadNotificationCount();
              if (newCount.value > 0) {
                newCount.value -= 1;
              }

              const url = `/pages/services-tools/job-order/view?id=${id}`;

              if (notification_type === 'joborder') {
                window.location.href = url;
              }

              if (notification_data === 'joborder') {
                this.$router.push(`${url}&activeTab=3`);
              }
            })
            .catch(() => {
              //
            })
            .finally(() => {
              //
            });
        }
      }

      loadNotificationCount();

      // Interval loading
      // reload every 1 minute
      setInterval(() => {
        loadNotificationCount();
      }, 60000);

      return {
        hasNew,
        newCount,
        items,

        perfectScrollbarSettings,
        redirectTo,
      };
    },
  };
</script>

<style></style>
